<template>
  <div class="lecture_warp">
    <TopCard ref="TopCardRef"
             text="公益讲义"
             :showBack="true" />
    <div class="subject_search">
      <div class="subject">
        <div v-for="item in subjectList"
             :class="{'active_subject':subjectId == item.subjectCode}"
             @click="checkSubject(item)"
             :key="item.subjectCode">
          {{item.subjectName}}
        </div>
      </div>
      <div class="search">
        <el-input placeholder="请输入讲义名称"
                  v-model="keyword">
          <i class="el-icon-search"
             slot="suffix"
             @click="getData">
          </i>
        </el-input>
      </div>
    </div>
    <div class="list">
      <div class="video"
           v-for="item in filtList"
           :key="item.url">
        <div class="subject">
          {{subject_name}}
        </div>
        <div class="boder">
          <div class="name">
            {{item.title}}
          </div>
          <div class="btn_wrap">
            <div class="btn"
                 @click="showViedo(item)">
              讲义详情
              <!-- <img src="@/assets/lecture/start.png"
                   alt=""> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <pagination ref="pagination"
                  :get-data="getData"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="total" />
    </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import { getStudentSubject } from '@/api/subject.js'
import { getType, getList } from '@/api/publicHandout.js'
export default {
  data () {
    return {
      subject_name: '',
      tableData: [],
      sourceCode: '',
      subjectList: [],
      subjectId: '',
      keyword: '',
      total: 0,
      page: 1,
      size: 20,
      list: []
    }
  },
  components: {
    TopCard
  },
  computed: {
    filtList () {
      var list = JSON.parse(JSON.stringify(this.list))
      if (this.keyword !== '') {
        list = this.list.filter(item => {
          if (item.title.indexOf(this.keyword) != -1) {
            return item
          }
        })
      }
      this.total = list.length
      return list.splice((this.page - 1) * this.size, this.page * this.size)
    }
  },
  mounted () {
    this.getSubject()
  },
  methods: {
    async getSubject () {
      const { data } = await getType()
      console.log(data)
      this.subjectList = data
      this.checkSubject(this.subjectList[0])
    },
    async getData (page, limit) {
      if (page || limit) {
        this.page = page
        this.size = limit
      }
      if (this.subjectId == '') return
      let params = {
        subjectCode: this.subjectId,
      }
      const { data } = await getList(params)
      this.list = data
    },
    checkSubject (item) {
      this.subjectId = item.subjectCode
      this.subject_name = this.subjectList.find(row => row.subjectCode == this.subjectId).subjectName
      this.getData()
    },
    showViedo (item) {
      window.open(item.url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.lecture_warp {
  background: #f6f6f6;
  .category {
    width: 100%;
    height: 80rem;
    display: flex;
    .label {
      width: 100rem;
      text-align: right;
      font-size: 20rem;
      font-weight: bold;
      color: #333333;
      height: 40rem;
      line-height: 40rem;
    }
    .categorys {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      font-size: 20rem;
      font-weight: bold;
      justify-content: flex-start;
      div {
        margin-right: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        cursor: pointer;
      }
      .cative {
        background: #2196f3 !important;
        border-radius: 2rem !important;
        color: #ececec !important;
        padding: 0 20rem !important;
      }
    }
  }
  .subject_search {
    display: flex;
    height: 44rem;
    margin-bottom: 10rem;
    margin-top: 10rem;
    position: relative;
    .subject {
      flex: 1;
      display: flex;
      margin-left: 25rem;
      div {
        font-size: 20rem;
        font-weight: bold;
        color: #333333;
        padding: 0 20rem;
        cursor: pointer;
      }
    }
    .search {
      position: absolute;
      width: 31%;
      height: 44rem;
      right: 50rem;
      ::v-deep .el-input--medium .el-input__inner {
        height: 44rem;
        line-height: 44rem;
        border-radius: 27rem;
        font-size: 18rem;
        font-weight: 500;
        color: #999999;
        background: white;
      }
      ::v-deep .el-icon-search {
        margin-right: 20rem;
        font-size: 22rem;
      }
      ::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10rem;
    height: calc(100vh - 225rem);
    overflow-y: auto;
    .video {
      width: 320rem;
      height: 280rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
      border-radius: 20rem;
      margin-left: 50rem;
      margin-top: 30rem;
      position: relative;
      .subject {
        width: 120rem;
        height: 44rem;
        background: #e6f0ff;
        border-radius: 1rem 1rem 15rem 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20rem;
        font-weight: bold;
        color: #2196f3;
        margin-left: 50%;
        transform: translateX(-50%);
        z-index: 1999;
        position: absolute;
      }
      .boder {
        width: 290rem;
        height: 250rem;
        border-radius: 10rem;
        border: 1rem solid #e5e5e5;
        position: absolute;
        left: 15rem;
        top: 15rem;
        .name {
          font-size: 20rem;
          font-weight: bold;
          color: #333333;
          margin: 30rem;
          height: 87rem;
          margin-top: 60rem;
        }
        .btn_wrap {
          display: flex;
          justify-content: center;
          margin-top: 40rem;
          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 180rem;
            height: 44rem;
            background: #2196f3;
            border-radius: 40rem;
            font-size: 22rem;
            color: #ffffff;
            cursor: pointer;
            img {
              width: 24rem;
              height: 24rem;
              margin-left: 12rem;
            }
          }
        }
      }
    }
  }
  .active_subject {
    border-bottom: 4rem solid #1890ff;

    color: #2196f3 !important;
  }
}
</style>