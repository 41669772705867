
import { instance } from '@/utils/http.js'

// 公益讲义列表
export const getList = function (params) {
  return instance({
    url: '/api/v1/teaching/welfare_point_lst',
    method: 'get',
    params
  })
}

// 公益讲义类型
export const getType = function () {
  return instance({
    url: '/api/v1/teaching/welfare_point_type_lst',
    method: 'get',
  })
}